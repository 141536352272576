import React, { useState } from 'react'
import { TableColumn } from '@maersk-global/mds-components-community/mc-c-table/types';
import { html, TemplateResult } from 'lit';
import { OrgModel } from './model';
import { MdsTable } from '../../components/Table/MdsTable';
import { McButton, McInput, McModal, McNotification } from '@maersk-global/mds-react-wrapper';
import { deleteOrganization, getOrganizations, saveOrganizations } from '../../api/services/organization';

const Org = () => {

  let numOfRecords = 20;
  const [tableData, setTableData] = React.useState<OrgModel[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [updatLoading, setUpdateLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [addLoading, setAddLoading] = React.useState(false);
  const [totalLength, setTotalLength] = React.useState(0);
  const [startPage, setStartPage] = React.useState(1);
  const [endPage, setEndPage] = React.useState(numOfRecords);
  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState<string>('');
  const [popup, setPopup] = useState({
    heading: '',
    body: '',
    appearance: '',
    hidden: true
  });
  const [openDeleteModel, setOpenDeleteModel] = useState(-1)
  const [openAddModel, setOpenAddModel] = useState(-1)

  const columnSortHandler = (event: CustomEvent): void => {
    event.preventDefault();
  }

  const fetchOrgData = async (page:number = 1): Promise<void> => {
    setLoading(true);
    setEditRowIndex(null);
    const orgData = await getOrganizations();
    const numOfRecords = orgData?.length ?? 0;
    setTotalLength(numOfRecords);
    const startIdx = (page * numOfRecords) - numOfRecords;
    const endIdx = page * numOfRecords;

    if (startIdx > totalLength) {
      setStartPage(1);
    } else if (startIdx + 1 !== startPage) {
      setStartPage(startIdx + 1);
    }

    if (endIdx !== endPage) {
      setEndPage(Math.min(endIdx, totalLength));
    }

    const orgRecords = orgData?.slice(startIdx, endIdx)?.map((val: { orgId: number | undefined; name: { toString: () => string }; }) => {
      const orgModelList = new OrgModel();
      orgModelList.orgId = val.orgId;
      orgModelList.name = val.name?.toString() || '-';
      return orgModelList;
    }) ?? [];

    setLoading(numOfRecords === 0);
    setTableData(orgRecords);

  };

  async function addOrg(event: any) {
    if (event.detail.action === "add") {
      setAddLoading(true);
      await saveOrganizations({ name: editingValue })
      setAddLoading(false);
      await fetchOrgData()
      setPopup({
        heading: 'Added',
        body: `Organization successfully added!`,
        appearance: 'success',
        hidden: false
      });
      setTimeout(() => {
        setPopup((prev) => ({ ...prev, hidden: true }));
      }, 3000);
    }
    setOpenAddModel(-1);
    setEditingValue('');
  }

  const deleteOrg = async (event: any) => {
    if (event.detail.action === "delete") {
      setDeleteLoading(true);
      await deleteOrganization(openDeleteModel);
      setDeleteLoading(false);
      await fetchOrgData()
      setPopup({
        heading: 'Deleted',
        body: `Organization successfully deleted!`,
        appearance: 'success',
        hidden: false
      });
      setTimeout(() => {
        setPopup((prev) => ({ ...prev, hidden: true }));
      }, 3000);
    }
    setOpenDeleteModel(-1);
  }

  React.useEffect(() => {

    // if (numOfRecords) {
      fetchOrgData()
    // }
  }, [
    // totalLength, startPage, endPage
  ]);

  const OrgColumns: Array<TableColumn> = [
    {
      id: 'name',
      label: 'Organizations',
      width: 'auto',
      align: 'left',
      nowrap: true,
      cellTemplate: (details): TemplateResult => {
        const row = details.rowData;
        const handleEditClick = (): void => {
          setEditRowIndex(row.orgId);
        };

        const handleOkClick = async () => {
          setUpdateLoading(true);
          await saveOrganizations({ orgId: row.orgId, name: editingValue || details.value });
          setUpdateLoading(false);
          setEditRowIndex(null);
          setEditingValue('');
          await fetchOrgData();
          setPopup({
            heading: 'Updated',
            body: `successfully updated!`,
            appearance: 'success',
            hidden: false
          });
          setTimeout(() => {
            setPopup((prev) => ({ ...prev, hidden: true }));
          }, 4000);
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
          setEditingValue(event.target.value);
          
        };

        const handleCancelClick = (): void => {
          setEditRowIndex(null);
          setEditingValue('');
        };


        return html`<div className='container' style=' display: -webkit-flex; -webkit-justify-content: space-between;' >
    ${editRowIndex === row.orgId
            ? html`<div className='text'>
          <mc-textarea hiddenlabel value=${editingValue} placeholder=${details.value} @input="${handleInputChange}"></mc-textarea>
          <div style='display: flex; gap: 10px; margin-top: 5px; margin-left: 80px;'>
            ${updatLoading ? html`<mc-button label="Update" variant="primary" fit="small" loading="true"></mc-button>`
                : html`<mc-button label="Update" variant="primary" fit="small" @click="${handleOkClick}"></mc-button>`
              }
            <mc-button label="Cancel" variant="primary" fit="small" @click="${handleCancelClick}"></mc-button>
          </div>
        </div>`
            : html`<div className='text'>${details.value}</div>`}
    <div className='icons' style='display: flex; gap: 20px; margin-right: 70px; '>
      <mc-button  hiddenlabel icon="pencil" variant="primary" fit="small" @click="${handleEditClick}"></mc-button >
      <mc-button hiddenlabel icon="trash" variant="primary" fit="small" @click="${() => setOpenDeleteModel(row.orgId)}"></mc-button >
    </div>
  </div>`;
      }
    }
  ];

  return (
    <div className='container-fluid'>
      <McModal
        heading="Delete Organization?"
        dimension="small"
        fit="small"
        open={openDeleteModel !== -1}
        focusstartanchorattribute="true"
        closing={deleteOrg}
      >
        <p>Are you sure you want to delete this organization?</p>
        <McButton slot="primaryAction" variant="primary" dialogAction="delete" loading={deleteLoading}>Delete</McButton>
        <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel" disabled={deleteLoading}>Cancel</McButton>
      </McModal>
      <McModal
        heading="Add Organization"
        dimension="small"
        fit="small"
        open={openAddModel !== -1}
        focusstartanchorattribute="true"
        closing={addOrg}
      >
        <div style={{ marginTop: '10px' }}>
          <McInput
            fit='small'
            name="name"
            label="Organization Name"
            type="text"
            value={editingValue}
            input={(e: any) => setEditingValue(e.target.value)}>
          </McInput>
        </div>

        <McButton slot="primaryAction" variant="primary" loading={addLoading} disabled={!editingValue} dialogAction="add">Add</McButton>
        <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel">Cancel</McButton>
      </McModal>
      <div className='orgTable' style={{ width: '700px' }}>
        <div style={{ margin: '10px 0px' }}>
          <McNotification
            heading={popup.heading}
            body={popup.body}
            appearance={popup.appearance as any}
            hidden={popup.hidden}
            icon={''}
            close={() => {
              setPopup({ ...popup, hidden: true })
            }}
          >
          </McNotification>
        </div>

        <div style={{ margin: '10px 0px' }}>
          <McButton label='Add Organization' click={() => setOpenAddModel(1)} />
        </div>

        <MdsTable
          dataLoading={loading}
          data={tableData}
          columns={OrgColumns}
          onSorting={columnSortHandler}
          maxHeight={'71vh'}
        />

      </div>
    </div >
  )
}

export default Org

