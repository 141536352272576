import { API } from "../axios";

async function blockCard(employeeId: string[], cardNos: string[], reason: string) {
    try {
        const res = await API.post('UserProfile/BlockCard/', { employeeId, cardNos, reason });
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function unBlockCard(employeeId: string[], cardNos: string[], reason: string) {
    try {
        const res = await API.post('UserProfile/UnblockCard/', { employeeId, cardNos, reason });
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function reportCard(empId: number) {
    try {
        const res = await API.get('UserProfile/ReportLostCard/' + empId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getLostCards() {
    try {
        const res = await API.get('UserProfile/GetLostCardUsers/-1');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getBlockedCards() {
    try {
        const res = await API.get('UserProfile/GetBlockedUsers/-1');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export { blockCard, unBlockCard, reportCard, getLostCards, getBlockedCards }