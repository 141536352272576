import { TableColumn } from "@maersk-global/mds-components-community/mc-c-table/types";
import {
  McButton,
  McCTable,
  McInput,
  McLoadingIndicator,
  McModal,
  McNotification,
  McRadio,
  McRadioGroup,
} from "@maersk-global/mds-react-wrapper";
import { useEffect, useRef, useState } from "react";
import {
  addAdmins,
  getAdminDetails,
  getUsers,
  removeAdmins,
} from "../../../api/services/userManagement";
import Select from "react-select";
import { getOrganizationsAsDropdown } from "../../../api/services/organization";
import AssignRole from "../../../models/AssignRole";
import { useProfileData } from "../../../hooks/useCurrentRole";
const ManageRoles = () => {
  useEffect(() => {
    loadAdmins();
  }, []);

  const [tableLoading, setTableLoading] = useState(true);
  const [tableLoading1, setTableLoading1] = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [tableData1, setTableData1] = useState<any>([]);
  const [isRemDisabled, setIsRemDisabled] = useState(true);
  const [isRemDisabled1, setIsRemDisabled1] = useState(true);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [isAddAdminLoad, setIsAddAdminLoad] = useState(false);
  const [adminsSelected, setAdminsSelected] = useState([]);
  const [radioRole, setRadioRole] = useState("");
  const [orgData, setOrgData] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [assignRoleData, setAssignRoleData] = useState<AssignRole>({
    role: "",
    org: [],
    mail: "",
  });
  const selectedValues = useRef<number[]>([]);
  const selectedValues1 = useRef<number[]>([]);
  const manageAdminColumn: Array<TableColumn> = [
    {
      id: "displayName",
      label: "Name",
      width: "auto",
      align: "left",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "role",
      label: "Role",
      width: "auto",
      align: "left",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "organization",
      label: "Organization",
      width: "auto",
      align: "left",
      nowrap: true,
      disableSorting: true,
    },
  ];
  const [popup, setPopup] = useState({
    heading: "",
    body: "",
    appearance: "",
    hidden: true,
  });
  const udata = useProfileData();

  const tbodyTemplate = (details: any) => {
    return details.html`
    <tbody>
      <tr>
        <td colspan="5" style="padding: 64px;">
          <mc-loading-indicator label="Loading">
          </mc-loading-indicator>
        </td>
      </tr>
    </tbody>`;
  };

  async function loadAdmins(status?: string) {
    let admins = await getUsers("admins");
    const adminEmails = admins.map((admin: any) => admin.userPrincipalName);
    let superAdmins = await getUsers("superAdmins");
    let adminDetails = await getAdminDetails(adminEmails);
    admins = admins.map((admin: any) => ({
      ...admin,
      role: "Admin",
      organization: adminDetails.filter(
        (adminDetail: any) => adminDetail.email === admin.userPrincipalName
      )[0].organizations.toString(),
    }));
    superAdmins = superAdmins.map((superAdmin: any) => ({
      ...superAdmin,
      role: "Super Admin",
      organization: "All",
    }));
    admins = admins.concat(superAdmins);
    //sort by name
    admins.sort((a: any, b: any) => {
      return a.displayName.localeCompare(b.displayName);
    });
    setTableData(admins);
    setAdminsSelected([]);
    setTableLoading(false);
    if (status) {
      setPopup({
        heading: "Success!",
        body: `Role successfully ${status}.`,
        appearance: "success",
        hidden: false,
      });
      setTimeout(() => {
        setPopup((prev) => ({ ...prev, hidden: true }));
      }, 4000);
    }
  }

  function rowSelection(event: any) {
    setAdminsSelected(event.detail.selectedValues);
    selectedValues.current = event.detail.selectedValues;
    if (selectedValues.current.length <= 0) {
      setIsRemDisabled(true);
      return;
    }
    setIsRemDisabled(false);
  }

  function rowSelection1(event: any) {
    selectedValues1.current = event.detail.selectedValues;
    if (selectedValues1.current.length <= 0) {
      setIsRemDisabled1(true);
      return;
    }
    setIsRemDisabled1(false);
  }

  async function deleteAdmin() {
    setShowTable(false);
    setTableLoading(true);

    const promises: any = [];
    adminsSelected.forEach((admin) => {
      promises.push(
        removeAdmins(
          tableData[admin].id,
          tableData[admin].role,
          tableData[admin].mail,
          udata.empid
        )
      );
    });
    selectedValues.current = [];
    setIsRemDisabled(true);
    await Promise.all(promises);
    setShowTable(true);
    await loadAdmins("Removed");
  }

  async function addAdmin() {
    setTableLoading1(true);
    setOpenAdminModal(true);
    const orgData = await getOrganizationsAsDropdown();
    setOrgData(orgData);
    setTableLoading1(false);
  }

  async function addAdminModal(event: any) {
    if (event.detail.action === "add") {
      setIsAddAdminLoad(true);
      const res: any = await addAdmins(udata.empid, assignRoleData);
      if (res.status === 200) {
        setTableLoading(true);
        loadAdmins("Assigned");
        setIsAddAdminLoad(false);
      } else {
        setIsAddAdminLoad(false);
        setPopup({
          heading: "Error!",
          body: res.response.data.message,
          appearance: "error",
          hidden: false,
        });
        setTimeout(() => {
          setPopup((prev) => ({ ...prev, hidden: true }));
        }, 10000);
      }
    }
    setAssignRoleData({ role: "", org: [], mail: "" });
    setRadioRole("");
    setOpenAdminModal(false);
  }

  function handleRadioRoleChange(event: any) {
    setRadioRole(event.detail);
    setAssignRoleData((prev) => ({ ...prev, role: event.detail }));
  }

  return (
    <>
      <div style={{ margin: "10px 0px" }}>
        <McNotification
          heading={popup.heading}
          body={popup.body}
          appearance={popup.appearance as any}
          hidden={popup.hidden}
          icon={""}
          close={() => {
            setPopup({ ...popup, hidden: true });
          }}
        ></McNotification>
      </div>
      <McModal
        heading="User Role Assignment"
        dimension="medium"
        fit="medium"
        open={openAdminModal}
        focusstartanchorattribute="true"
        closing={addAdminModal}
      >
        {openAdminModal && (
          <>
            <div
              style={{
                marginBottom: radioRole === "SuperAdmin" ? "20px" : "0",
              }}
            >
              <McRadioGroup
                name="role"
                legend="Role"
                orientation="horizontal"
                change={handleRadioRoleChange}
              >
                <McRadio name="role" value="Admin" label="Admin"></McRadio>
                <McRadio
                  name="role"
                  value="SuperAdmin"
                  label="Super Admin"
                ></McRadio>
              </McRadioGroup>
            </div>

            <div style={{height: 190}}>
              {radioRole !== "SuperAdmin" && (
                <div style={{ margin: "20px 0" }}>
                  <h6>Organization</h6>
                  <Select
                    options={orgData}
                    isMulti
                    isLoading={tableLoading1}
                    closeMenuOnSelect={false}
                    onChange={(e: any) => {
                      setAssignRoleData((prev) => ({
                        ...prev,
                        org: e.map((org: any) => org.value),
                      }));
                    }}
                  />
                </div>
              )}

              <McInput
                fit="small"
                name="email"
                label="Email"
                type="email"
                input={(e: any) =>
                  setAssignRoleData((prev) => ({
                    ...prev,
                    mail: e.target.value,
                  }))
                }
              ></McInput>
              {/* <Select
                options={userData}
                isLoading={tableLoading1}
                onChange={(e: any) => {
                  setAssignRoleData((prev) => ({
                    ...prev,
                    userId: e.value.id,
                    mail: e.value.mail,
                  }));
                }}
              /> */}
            </div>
            {/* <McCTable
              data={tableData1}
              columns={manageAdminColumn}
              rowselector
              rowselectchange={rowSelection1}
              tbodyTemplate={tableLoading1 ? tbodyTemplate : undefined}
            /> */}
          </>
        )}
        <McButton
          slot="primaryAction"
          variant="primary"
          loading={isAddAdminLoad}
          disabled={
            assignRoleData.role === "" ||
            assignRoleData.mail === "" ||
            (assignRoleData.role === "Admin" && assignRoleData.org.length <= 0)
          }
          dialogAction="add"
        >
          Add
        </McButton>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
        >
          Cancel
        </McButton>
      </McModal>

      <div className="table-action">
        <McButton label="Assign role" click={addAdmin} />
        <McButton
          label="Remove role "
          click={deleteAdmin}
          disabled={isRemDisabled}
        />
      </div>

      {showTable ? (
        <div style={{ width: "80%" }}>
          <McCTable
            data={tableData}
            columns={manageAdminColumn}
            selecteddatakeyvalues={adminsSelected}
            rowselector
            rowselectchange={rowSelection}
            tbodyTemplate={tableLoading ? tbodyTemplate : undefined}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          {" "}
          <McLoadingIndicator />
        </div>
      )}
    </>
  );
};

export default ManageRoles;
