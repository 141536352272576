import { useAccount } from '@azure/msal-react';
import { McInput, McSelect } from '@maersk-global/mds-react-wrapper';
import { useEffect, useState } from 'react'
import { getOrganizationsAsDropdown } from '../../api/services/organization';
import User from '../../models/User';
import './RegisterForm.scss';

const RegisterForm = ({ update, user, setUser }: { update?: boolean, user: User, setUser: any }) => {
    const acc = useAccount();
    const regnoRegex = /^\d{4}$/; // for bank registration number
    const bankAccRgx = /^\d{10}$/; // for bank account
    const pemailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // for personal email
    const cardRgx = /^\d[0-9.]{0,10}\d$/; // for rebate card
    const [org, setOrg] = useState<any>([]);
    const [selectedOrg, setSelectedOrg] = useState<any>([])

    useEffect(() => {
        fetchOrg();
        if (update) {
            setSelectedOrg([{ label: user.organisationName, value: user.organisation.toString() }]);
            setUser(user);
        } else {
            setSelectedOrg(null);
            if (acc && acc.idTokenClaims?.uid) {
                setUser((prev: any) => {
                    return { ...prev, userId: acc.idTokenClaims?.uid as string, fullName: acc.name, email: acc.username }
                });
            }
        }
    }, [])

    const fetchOrg = async () => {
        const orgs = await getOrganizationsAsDropdown();
        setOrg(orgs);
    }

    const handleChange = (event: any): void => {
        if (event.detail.selectedOptions) {
            if (event.detail.selectedOptions.length > 0) {
                setSelectedOrg(event.detail.selectedOptions[0].label)
                setUser({
                    ...user,
                    'organisation': event.detail.selectedOptions[0].value,
                    'organisationName': event.detail.selectedOptions[0].label,
                })
                return;
            } else {
                setUser({
                    ...user,
                    'organisation': -1,
                    'organisationName': '',
                })
                return;
            }
        }
        setUser({
            ...user,
            [event.target.name]: event.target.value
        })
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5px' }}>
            <McInput fit='small' label="Name" name="fullName" width={100} value={user.fullName} disabled={!!user.fullName}></McInput>

            <div className="hor-field">
                <div style={{ width: '49%' }}>
                    <McInput fit='small' label="Emp Id" name="userId" value={user.userId} disabled={!!user.userId}></McInput>
                </div>
                <div style={{ width: '49%' }}>
                    <McInput fit='small' type="email"
                        value={user.email}
                        disabled={!!user.email}>
                        <span slot='label'>Email</span>
                    </McInput>
                </div>
            </div>

            {/* <div className="hor-field"> */}
                <div 
                // style={{ width: '49%' }}
                >
                    <McInput
                        fit='small'
                        name='primaryCard'
                        label="Rebate Card Number"
                        inputMode='numeric'
                        disabled={!!(user.userId && acc?.idTokenClaims?.uid != user.userId)}
                        value={user.primaryCard}
                        errormessage='Card number is invalid. Please enter a 8-digit number'
                        invalid={user.primaryCard.replaceAll('.','').length != 8 && !(user.primaryCard === "")}
                        input={(e: CustomEvent) => handleChange(e)}
                    >
                    </McInput>
                </div>
                {/* <div style={{ width: '49%' }}>
                    <McInput
                        fit='small'
                        name='noOfCards'
                        label="Number of Rebate Cards"
                        inputMode='numeric'
                        disabled={!!(user.userId && acc?.idTokenClaims?.uid != user.userId)}
                        value={user.noOfCards.toString()}
                        invalid={user.noOfCards <= 0 || user.noOfCards > 5}
                        errormessage='Card number is invalid. Enter value less than 5'
                        input={(e: CustomEvent) => handleChange(e)}
                    >
                    </McInput>
                </div> */}
            {/* </div> */}

            <McInput
                fit='small'
                name="personalEmail"
                label="Personal Email"
                type="email"
                value={user.personalEmail}
                invalid={!pemailRegex.test(user.personalEmail) && !(user.personalEmail === "")}
                errormessage="Invalid Personal Email"
                input={(e: CustomEvent) => handleChange(e)}>
            </McInput>

            {update ? <McSelect name='org' label="Organization" fit='small' options={org} value={selectedOrg} /*placeholder={"Select Organization"}*/ change={(e: CustomEvent) => handleChange(e)}></McSelect>
                : <McSelect name='org' label="Organization" fit='small' options={org} /*placeholder={"Select Organization"}*/ change={(e: CustomEvent) => handleChange(e)}></McSelect>}

            <McInput
                fit='small'
                name="bankRegistration"
                label="Bank Registration Number"
                inputMode='numeric'
                maxlength={4}
                value={user.bankRegistration}
                invalid={!regnoRegex.test(user.bankRegistration) && !(user.bankRegistration === "")}
                errormessage="Bank registration number is invalid. Please enter a 4-digit number"
                input={(e: CustomEvent) => handleChange(e)}>
            </McInput>

            <McInput
                fit='small'
                name="bankAccount"
                label="Bank Account Number (Danish)"
                inputMode='numeric'
                maxlength={10}
                value={user.bankAccount}
                invalid={!bankAccRgx.test(user.bankAccount) && !(user.bankAccount === "")}
                errormessage="Please enter a 10-digit number. Prefix with 0's if less."
                input={(e: CustomEvent) => handleChange(e)}>
            </McInput>

            {!update && <McInput
                fit='small'
                name="rbankAccount"
                label="Retype Bank Account Number"
                type="password"
                maxlength={10}
                value={user.rbankAccount}
                invalid={!(JSON.stringify(user.rbankAccount) === JSON.stringify(user.bankAccount)) && !(user.rbankAccount === "")}
                errormessage="Input doesn't match with Bank Account "
                input={(e: CustomEvent) => handleChange(e)}>
            </McInput>}
        </div>
    )
}

export default RegisterForm