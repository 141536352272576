import {
  McButton,
  McCheckbox,
  McCheckboxGroup,
  McInput,
  McModal,
  McNotification,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import React, { useEffect, useState } from "react";
import { getReportFields, getReports } from "../../api/services/reports";
import { getOrganizationsByAdmin } from "../../api/services/organization";

const Reports = () => {
  const [reportFields, setReportFields] = useState<any[]>([]);
  const [selectedOrg, setSelectedOrg] = React.useState<any>(-1);
  const [selectedField, setSelectedField] = useState<string[]>([
    "User Id",
    "Secondary Email",
    "Primary Email",
    "Organisation",
    "Full Name",
    "Card Number",
    "Card Blocked",
    "Block Date",
    "Bank Registartion",
    "Bank Account",
  ]);
  const [org, setOrg] = useState<any>([]);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [popup, setPopup] = useState({
    heading: "",
    body: "",
    appearance: "",
    hidden: true,
  });

  useEffect(() => {
    fetchOrg();
    fetchReportFields();
  }, []);

  const fetchReportFields = async () => {
    const reportData = await getReportFields();
    setReportFields(reportData);
  };

  const fetchOrg = async () => {
    const orgs = await getOrganizationsByAdmin();
    if (orgs.length === 1) {
      setSelectedOrg(orgs[0].value);
    }
    setOrg(orgs);
  };
  const fetchReport = async () => {
    if (selectedField.length === 0) {
      setError(true);
      return;
    }
    setError(false);
    getReports(selectedField, selectedOrg).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Report.zip");
      document.body.appendChild(link);
      link.click();
      setPassword(response.headers["x-excel-pass"]);
      setPopup({
        heading: "Success",
        body: "Report has been generated successfully!",
        appearance: "success",
        hidden: false,
      });
      setTimeout(() => {
        setPopup((prev) => ({ ...prev, hidden: true }));
      }, 4000);
    });
  };

  return (
    <div className="container-fluid">
      <McModal
        heading="Excel Password"
        dimension="small"
        fit="small"
        open={password}
        focusstartanchorattribute="true"
        closing={() => {
          setPassword(null);
          setIsDisabled(true);
        }}
      >
        <div
          style={{ marginTop: "10px", cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(password);
            setIsDisabled(false);
          }}
        >
          <div style={{ color: "red", marginBottom: "10px" }}>
            Note: Password is visible only once. Please copy it before closing
            this dialog.
          </div>
          <McInput
            fit="small"
            disabled
            value={password}
            hiddenlabel
            icon="copy"
            iconposition="right"
          ></McInput>
        </div>

        <McButton
          slot="primaryAction"
          variant="primary"
          disabled={isDisabled}
          dialogAction="block"
        >
          Password Copied
        </McButton>
      </McModal>

      <div style={{ margin: "10px 0px" }}>
        {!popup.hidden && (
          <McNotification
            heading={popup.heading}
            body={popup.body}
            appearance={popup.appearance as any}
            hidden={popup.hidden}
            icon={""}
            close={() => {
              setPopup({ ...popup, hidden: true });
            }}
          ></McNotification>
        )}
      </div>
      <div className="register-card" style={{ padding: "20px" }}>
        <div style={{ display: "grid", gridGap: "16px" }}>
          <McSelect
            label="Organization"
            // placeholder=''
            options={[
              { label: org.length === 1 ? org[0].label : "All", value: -1 },
              ...(org.length === 1 ? [] : org),
            ]}
            selectedindex={[0]}
            change={(e) => setSelectedOrg(e.detail.selectedOptions[0].value)}
          ></McSelect>
          <McCheckboxGroup
            name="reportFields"
            legend="Please select the fields to be included in the Report"
            change={(e) => {
              setSelectedField(e.detail);
            }}
            errormessage="Please select atleast one field"
            invalid={error}
          >
            {reportFields.map((field: string) => (
              <McCheckbox
                name="reportFields"
                fit="large"
                label={field}
                value={field}
                key={field}
                checked={selectedField.includes(field)}
              />
            ))}
          </McCheckboxGroup>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "15px" }}>
        <McButton
          fit="large"
          width="full-width"
          label="Get Report"
          onClick={fetchReport}
        />
      </div>
    </div>
  );
};

export default Reports;
