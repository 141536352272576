// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqs {
  display: -ms-inline-flexbox;
  width: 100%;
  max-width: 92%;
  margin: 50px 0 50px 50px;
  padding: 10px;
  align-items: flex-start;
  align-content: center;
  padding-left: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.container-fluid .question {
  padding: 5px;
  background-color: #ffffff;
}

.answer {
  padding-left: 8px;
  color: #545454;
}`, "",{"version":3,"sources":["webpack://./src/pages/FAQ/FAQ.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,WAAA;EACA,cAAA;EACA,wBAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;EACA,2CAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;AACJ;;AAGA;EACI,iBAAA;EACA,cAAA;AAAJ","sourcesContent":[".faqs {\n    display: -ms-inline-flexbox;\n    width: 100%;\n    max-width: 92%;\n    margin: 50px 0 50px 50px;\n    padding: 10px;\n    align-items: flex-start;\n    align-content: center;\n    padding-left: 15px;\n    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);\n}\n\n.container-fluid .question {\n    padding: 5px;\n    background-color: #ffffff;\n}\n\n\n.answer{\n    padding-left: 8px;\n    color:#545454; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
