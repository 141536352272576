import React, { useEffect, useState } from 'react'
import './FAQ.scss'
import { McButton, McCAccordion, McCAccordionItem, McModal, McTextarea, McCCard, McLoadingIndicator } from '@maersk-global/mds-react-wrapper';
import useCurrentRole from '../../hooks/useCurrentRole';
import Faq from '../../models/Faq';
import { deleteFaq, getAllFaq, saveFaq } from '../../api/services/faq';
const FAQ = () => {
  const currentRole = useCurrentRole();
  const role = currentRole[0];
  const [openModal, setModalFlag] = React.useState(false);
  const [openModalAdd, setModalFlagAdd] = React.useState(false);
  const [indexvalue, setIndexValue] = React.useState(1);
  const [openDeleteModel, setOpenDeleteModel] = useState(false)
  const [idx, setIdx] = useState(0);
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [faq, setFaq] = useState({ question: "", answer: "" })
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState<boolean | string>(false);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    setLoading(true);
    const faqs = await getAllFaq();
    setFaqs(faqs);
    setLoading(false);
  }
  const clickEventForAdd = () => {
    setModalFlagAdd(!openModalAdd);
  }
  const updateEvent = (e: any, i: any) => {
    setFaq({
      question: faqs[i].question,
      answer: faqs[i].answer,
    })
    setModalFlag(!openModal);
    setIndexValue(i)
  }
  const deleteEvent = (e: any, i: any) => {
    setOpenDeleteModel(!openDeleteModel);
    setIdx(i)
  }
  const handleDelete = async (event: any) => {
    if (event.detail.action === "delete") {
      setActionLoading('delete');
      await deleteFaq(idx);
      setActionLoading(false);
      fetchFaqs();
    }
    setOpenDeleteModel(false);
  }

  const handleUpdate = async (event: any) => {
    if (event.detail.action === "update") {
      if (faq.question.length <= 10 || faq.answer.length <= 10) {
        return;
      }
      setActionLoading('update');
      await saveFaq({ faqId: faqs[indexvalue].faqId, question: faq.question, answer: faq.answer })
      setActionLoading(false);
      fetchFaqs();
    }
    setFaq({ question: "", answer: "" });
    setModalFlag(false);
  }
  const handleSubmit = async (event: any) => {
    if (event.detail.action === "add") {
      if (faq.question.length <= 10 || faq.answer.length <= 10) {
        return;
      }
      let postData = {
        question: faq.question,
        answer: faq.answer
      };

      setActionLoading('add');
      await saveFaq(postData);
      setActionLoading(false);
      fetchFaqs();
    }
    setFaq({ question: "", answer: "" });
    setModalFlagAdd(false)
  }
  function handleInput(event: any): void {
    setFaq(prev => ({ ...prev, [event.target.name]: event.target.value }));
  }
  function setInputValueForUpdate(event: any): void {
    setFaq(prev => ({ ...prev, [event.target.name]: event.target.value }));
  }
  return (
    loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }} > <McLoadingIndicator /></div >
      : <>
        <div className='container-fluid'>
          {role === 'Admin' || role === 'SuperAdmin' ? <><McCCard
            heading="Faq"
            variant="bordered"
            orientation="vertical"
            fit="medium"
          >
            <div slot='body'>

              {faqs.map((faq, i) => {
                return <div className="container" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px" }} key={faq.faqId}>
                  <div className="col-md-11" style={{ paddingBottom: "10px" }}>

                    <McCAccordion>
                      <McCAccordionItem title={faq.question + '?'} id="i" className='question'  >
                        <div style={{ paddingTop: "5px" }}>Ans: {faq.answer}</div>
                      </McCAccordionItem>
                    </McCAccordion>
                  </div>

                  <McButton hiddenlabel icon="pencil" variant="primary" style={{ margin: '0px 5px' }} click={(e: CustomEvent) => updateEvent(e, i)} />

                  <McButton hiddenlabel icon="trash" variant="primary" style={{ margin: '0px 5px' }} click={(e: CustomEvent) => deleteEvent(e, faq.faqId)} />

                </div>


              })}
            </div>
            <div slot='actions'>
              In case of any queries contact <a href="mailto: HQFM@maersk.com">HQFM@maersk.com</a>
            </div>
          </McCCard>
            <div style={{ marginTop: "10px", marginLeft: "2px" }}>
              <McButton fit="medium" variant="primary" click={clickEventForAdd} dialogAction="Add">Add</McButton>

            </div></>
            : <McCCard
              heading="Faq"
              variant="bordered"
              orientation="vertical"
              fit="medium"
            >
              <div slot='body'>

                {faqs.map((faq, i) => {
                  return <div className="container" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px" }} key={faq.faqId}>
                    <div className="col-md-11" style={{ paddingBottom: "10px" }}>

                      <McCAccordion>
                        <McCAccordionItem title={faq.question + '?'} id="i" className='question'  >
                          <div style={{ paddingTop: "5px" }}>Ans: {faq.answer}</div>
                        </McCAccordionItem>
                      </McCAccordion>
                    </div>

                  </div>
                })}
              </div>
              <div slot='actions'>
                In case of any queries contact <a href="mailto: HQFM@maersk.com">HQFM@maersk.com</a>
              </div>

            </McCCard>}

          {openModalAdd && <McModal heading='Add FAQ' open={openModalAdd} dimension='medium' height='620px' closing={handleSubmit}>
            <div>
              <div style={{ margin: "15px", marginLeft: "0px" }}>
                <McTextarea
                  name="question"
                  label="Question"
                  value={faq.question}
                  rows={5}
                  input={(e: CustomEvent) => handleInput(e)}
                  invalid={faq.question.length > 0 && faq.question.length <= 10}
                  errormessage="Question should be greater than 10 characters"
                >
                </McTextarea>
              </div>
              <div style={{ margin: "15px", marginLeft: "0px" }}>

                <McTextarea
                  name="answer"
                  label="Answer"
                  value={faq.answer}
                  rows={6}
                  input={(e: CustomEvent) => handleInput(e)}
                  invalid={faq.answer.length > 0 && faq.answer.length <= 10}
                  errormessage="Answer should be greater than 10 characters"
                >
                </McTextarea>
              </div>

            </div>
            <McButton slot="primaryAction" variant="primary" dialogAction="add" style={{ marginRight: "20px" }} loading={actionLoading === 'add'} disabled={faq.question.length <= 10 || faq.answer.length <= 10}>Add</McButton>
            <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel" disabled={actionLoading === 'add'}>Cancel</McButton>
          </McModal>}


          <div>

          </div>
          {openModal && <McModal heading='Update FAQ' open={openModal} dimension='medium' height='620px' closing={handleUpdate}>
            <div>
              <div style={{ margin: "15px", marginLeft: "0px" }}>

                <McTextarea
                  name="question"
                  label="Question"
                  value={faq.question}
                  rows={6}
                  input={(e: CustomEvent) => setInputValueForUpdate(e)}
                  invalid={faq.question.length > 0 && faq.question.length <= 10}
                  errormessage="Question should be greater than 10 characters"
                ></McTextarea>
              </div>
              <div style={{ margin: "15px", marginLeft: "0px" }}>

                <McTextarea
                  name="answer"
                  label="Answer"
                  value={faq.answer}
                  rows={6}
                  input={(e: CustomEvent) => setInputValueForUpdate(e)}
                  invalid={faq.answer.length > 0 && faq.answer.length <= 10}
                  errormessage="Answer should be greater than 10 characters"
                ></McTextarea>
              </div>



            </div>

            <McButton slot="primaryAction" variant="primary" dialogAction="update" style={{ marginLeft: "2px" }} loading={actionLoading === 'update'} disabled={faq.question.length <= 10 || faq.answer.length <= 10}>Update</McButton>
            <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel" disabled={actionLoading === 'update'}>Cancel</McButton>

          </McModal>}

        </div>
        <McModal
          heading="Delete Faq?"
          dimension="small"
          fit="small"
          open={openDeleteModel}
          focusstartanchorattribute="true"
          closing={handleDelete}
        >
          <p>Are you sure you want to delete this Faq?</p>
          <McButton slot="primaryAction" variant="primary" dialogAction="delete" loading={actionLoading === 'delete'}>Delete</McButton>
          <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel" disabled={actionLoading === 'delete'}>Cancel</McButton>
        </McModal>
      </>

  )
}

export default FAQ