import { Outlet, useLocation } from 'react-router-dom';
import { TopBar } from '../TopBar/TopBar';
import { SideNav } from '../SideNav/SideNav';
import Footer from '../Footer/Footer';
import './PageLayout.scss';
const PageLayout = () => {
    const { pathname } = useLocation();
    return (

        <div>
            <div className='header'>
                <TopBar selectedNav={pathname}></TopBar>
            </div>

            <div className='sidenav-container'>
                <SideNav />
            </div>

            <div className="main">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default PageLayout