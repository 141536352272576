import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import FAQ from "./FAQ/FAQ";
import Home from "./Home/Home";
import MyProfile from "./MyProfile/MyProfile";
import LostmyCard from "./LostMyCard/LostMyCard";
import Organizations from "./Organizations/Organizations";
import News from "./News/News";
import Reports from "./Reports/Reports";
import UserManagement from "./UserManagement/UserManagement";
import PageLayout from "../components/pageLayout/PageLayout";
import RequireAuth from "../components/RequireAuth";
import { McLoadingIndicator } from "@maersk-global/mds-react-wrapper";
import { useAccount } from "@azure/msal-react";
import { addUsers } from "../api/services/userManagement";

const Pages = () => {
  const [isLoading, setIsLoading] = useState(true);
  const account = useAccount();
  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    if (
      !account?.idTokenClaims?.roles &&
      localStorage.getItem("userAssignedRole") != "true"
    ) {
      try {
        localStorage.setItem("userAssignedRole", "true");
        await addUsers(account?.localAccountId as any);
      } catch (error) {
        console.log("err:", error);
        localStorage.setItem("userAssignedRole", "false");
      }
    }
    setIsLoading(false);
  }
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {" "}
      <McLoadingIndicator />
    </div>
  ) : (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route
          element={
            <RequireAuth allowedRoles={["User", "Admin", "SuperAdmin"]} />
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/lost-my-card" element={<LostmyCard />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/news" element={<News />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={["Admin", "SuperAdmin"]} />}>
          <Route path="/reports" element={<Reports />} />
          <Route path="/user-management" element={<UserManagement />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
          <Route path="/organizations" element={<Organizations />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Pages;
