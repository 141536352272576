import React, { useState } from 'react'
import { McCCard, McButton, McNotification } from '@maersk-global/mds-react-wrapper'
import { useAccount } from '@azure/msal-react'
import { reportCard } from '../../api/services/card'

const LostmyCard = () => {
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({
    heading: '',
    body: '',
    appearance: '',
    hidden: true
  });
  const account = useAccount();

  async function reportLostCard() {
    setLoading(true);
    await reportCard(account?.idTokenClaims?.uid as number)
    setLoading(false);
    setPopup({
      heading: 'Success',
      body: 'Card has been reported succcessfully!',
      appearance: 'success',
      hidden: false
    });
    setTimeout(() => {
      setPopup((prev) => ({ ...prev, hidden: true }));
    }, 4000);
  }

  return (
    <div className='container-fluid'>
      <div style={{ margin: '10px 0px' }}>
        {!popup.hidden && <McNotification
          heading={popup.heading}
          body={popup.body}
          appearance={popup.appearance as any}
          hidden={popup.hidden}
          icon={''}
          close={() => {
            setPopup({ ...popup, hidden: true })
          }}
        >
        </McNotification>}
      </div>
      <McCCard
        image="maersk.jpg"
        imagepercent={35}
        padding='15px'
        heading="Lost Your Card?"
        variant="bordered"
        orientation="horizontal"
        fit="large"
      >
        <div slot="body">Please report your lost/stolen card to us immediately by clicking on the button below. We will close your existing card. The accumulated rebate on your old card will be paid at the time of annual rebate payout to the bank account registered for that card.<br />
          <br />Please note that, Rebate card comes in a set of two with identical numbers on it, hence single card cannot be issued.<br />
          <br />You can collect your new set at Esplanaden 50 Front Office in Copenhagen or in your respective office in Denmark. For offshore personnel, please contact <a href="mailto: HQFM@maersk.com">HQFM@maersk.com</a>  to order new set delivered to your home address in Denmark. Please remember to register your new card prior using it, so we can ensure you will receive your discount.<br /><br />
        </div>
        <div slot="actions" >
          <McButton label="Report My Lost Card" loading={loading} width='full-width' fit="large" click={reportLostCard}></McButton>
        </div>
      </McCCard>
    </div>
  )
}

export default LostmyCard