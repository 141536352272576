// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 70px;
  left: 0%;
  right: 0%;
  top: 0px;
  background: #ffffff;
  z-index: 1;
}

.top-bar-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 44px;
  margin: 0 auto;
  z-index: 1;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03);
}

.utility-container {
  display: flex;
}

.logo-home {
  display: flex;
  padding-left: 75px;
}

.logo-text {
  font-size: var(--mp-font-size);
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  color: #00243D;
}

.signout-icon {
  padding-top: 16px;
  padding-right: 10px;
}

.dropdown-toggle {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
  .hide-small-screen {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TopBar/TopBar.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,QAAA;EACA,mBAAA;EACA,UAAA;AACF;;AACA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;EACA,UAAA;EACA,4HAAA;AAEF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;AACF;;AAGA;EACE,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;AAAF;;AAGA;EACE,iBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE;IACE,aAAA;EAAF;AACF","sourcesContent":[".topbar {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  height: 70px;\n  left: 0%;\n  right: 0%;\n  top: 0px;\n  background: #ffffff;\n  z-index: 1;\n}\n.top-bar-container {\n  display: flex;\n  height: 100%;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: 0px 44px;\n  margin: 0 auto;\n  z-index: 1;\n  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.04),\n    0px 3px 14px 2px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03);\n}\n\n.utility-container {\n  display: flex;\n}\n\n.logo-home {\n  display: flex;\n  padding-left: 75px;\n\n}\n\n.logo-text {\n  font-size: var(--mp-font-size);\n  font-weight: 700;\n  line-height: 24px;\n  text-transform: uppercase;\n  padding-left: 10px;\n  color: #00243D;\n}\n\n.signout-icon{\n  padding-top: 16px;\n  padding-right: 10px;\n}\n\n.dropdown-toggle {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n@media screen and (max-width: 1100px) {\n  .hide-small-screen {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
