import Theme from "./Theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import React, { useEffect, useRef, useState } from 'react';
import { McButton, McIcon, McModal } from "@maersk-global/mds-react-wrapper";
import { LexicalEditor } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import "./styles.scss"
import moment from "moment";
import useCurrentRole from "../../hooks/useCurrentRole";
import { deleteNews } from "../../api/services/news";
import News from "../../models/News";

function RefPlugin({ editorRef }: { editorRef: any }) {
  const [editor] = useLexicalComposerContext()
  editorRef.current = editor
  return null
}

export function ReadOnlyEditor({ newsData, idx, deleteN, updateNews }: {
  newsData: News, idx: number, deleteN: any, updateNews: any
}) {

  useEffect(() => {
    const editor = editorRef.current;
    editor?.setEditorState(editor.parseEditorState(newsData.content));
  }, [newsData.content]);

  useEffect(() => {
    if (idx === 0) {
      setIsActive(false)
    }
  }, []);

  const editorRef = useRef<LexicalEditor | null>(null)
  const [isActive, setIsActive] = useState(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false)
  const role = useCurrentRole();
  const editorConfig = {
    namespace: "MyEditor",
    theme: Theme,
    editable: false,
    editorState: newsData.content,
    onError: (error: any) => { throw error },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
    ]
  };

  function handleDelete(event: any) {
    if (event.detail.action === "delete") {
      deleteN(idx);
      // deleteNews(newsData.newsId);
    }
    setOpenDeleteModel(false);
  }

  return (
    <>
      <McModal
        heading="Delete News?"
        dimension="small"
        fit="small"
        open={openDeleteModel}
        focusstartanchorattribute="true"
        closed={handleDelete}
      >
        <p>Are you sure you want to delete this news?</p>
        <McButton slot="primaryAction" variant="primary" dialogAction="delete">Delete</McButton>
        <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel">Cancel</McButton>
      </McModal>
      <div className="news-card">
        <div className="news-header">
          <div className="news-heading" style={{ marginLeft: '15px' }} onClick={() => setIsActive(prev => !prev)}>
            <h2 style={{ margin: '0px', overflowWrap: 'anywhere' }}>{newsData.title}</h2>
            <p style={{ fontSize: '12px', color: 'grey', margin: '0px' }}>{moment(newsData.updatedOn).format('LL')}</p>
          </div>
          <div className="news-actions">
            <McButton hidden={role.includes("User")} hiddenlabel icon="pencil" variant="primary" style={{ margin: '0px 5px' }} onclick={() => updateNews(newsData.content, newsData.title, idx)} />
            <McButton hidden={role.includes("User")} hiddenlabel icon="trash" variant="primary" style={{ margin: '0px 5px' }} onclick={() => setOpenDeleteModel(true)} />
            <McIcon onClick={() => setIsActive(prev => !prev)} icon={isActive ? "chevron-down" : "chevron-up"} />
          </div>
        </div>
        <hr hidden={isActive} style={{ margin: '0px', border: '1.5px solid' }} />
        <div hidden={isActive} className="news-body">
          <LexicalComposer initialConfig={editorConfig}>
            <RichTextPlugin
              contentEditable={<ContentEditable className="rEditor-input" />}
              placeholder={null}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <RefPlugin editorRef={editorRef} />
            <ListPlugin />
          </LexicalComposer>
        </div>
      </div>
    </>
  );
}
