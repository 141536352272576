import AssignRole from "../../models/AssignRole";
import { API, cloudAPI } from "../axios";

async function getUsers(type: string) {
    try {
        const calls = [];
        if (type == "users" || type == "all") {
            calls.push(cloudAPI.get(`/Groups/${process.env.REACT_APP_USERS_GRP_ID}/Members`))
        }
        if (type == "admins" || type == "all") {
            calls.push(cloudAPI.get(`/Groups/${process.env.REACT_APP_ADMINS_GRP_ID}/Members`))
        }
        if (type == "superAdmins" || type == "all") {
            calls.push(cloudAPI.get(`/Groups/${process.env.REACT_APP_SUPERADMINS_GRP_ID}/Members`))
        }
        const response = await Promise.all(calls);
        const users: any = [];
        response.forEach(r => users.push(...r.data.Value))
        users.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
        return users;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function addAdmins(userId: string, orgData: AssignRole) {
    let res;
    try {
        await cloudAPI.put('/GroupUpdate', {
            "groupId": process.env.REACT_APP_USERS_GRP_ID,
            "groupAction": "removeMember",
            "refObject": orgData.mail
        });

        if (orgData.role === "Admin") {
            res = await cloudAPI.put('/GroupUpdate', {
                "groupId": process.env.REACT_APP_ADMINS_GRP_ID,
                "groupAction": "addMember",
                "refObject": orgData.mail
            });
        } else if (orgData.role === "SuperAdmin") {
            res = await cloudAPI.put('/GroupUpdate', {
                "groupId": process.env.REACT_APP_SUPERADMINS_GRP_ID,
                "groupAction": "addMember",
                "refObject": orgData.mail
            });
        }

        await API.post('UserManagement/SaveAdminOrg', { userId, adminEmail: orgData.mail, orgID: orgData.role === "SuperAdmin" ? [-1] : orgData.org });
        return res;
    } catch (error) {
        console.log('Error:', error);
        return error;
    }
}

async function addUsers(userId: string) {
    try {
        await cloudAPI.put('/GroupUpdate', {
            "groupId": process.env.REACT_APP_USERS_GRP_ID,
            "groupAction": "addMember",
            "refObject": userId
        });
    } catch (error) {
        console.log('Error:', error);
    }
}

async function removeAdmins(userId: string, role: string, adminEmail: string, empID: string) {
    try {
        if (role === "Admin") {
            await cloudAPI.put('/GroupUpdate', {
                "groupId": process.env.REACT_APP_ADMINS_GRP_ID,
                "groupAction": "removeMember",
                "refObject": userId
            });
        } else if (role === "Super Admin") {
            await cloudAPI.put('/GroupUpdate', {
                "groupId": process.env.REACT_APP_SUPERADMINS_GRP_ID,
                "groupAction": "removeMember",
                "refObject": userId
            });
        }

        await cloudAPI.put('/GroupUpdate', {
            "groupId": process.env.REACT_APP_USERS_GRP_ID,
            "groupAction": "addMember",
            "refObject": userId
        });

        await API.post('UserManagement/SaveAdminOrg', { userId: empID, adminEmail, orgID: [] });
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getAdminDetails(adminEmails: string[]) {
    try {
        const res = await API.post('UserManagement/GetAdminDetails', { emails: adminEmails });
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export { getUsers, addAdmins, removeAdmins, addUsers, getAdminDetails };