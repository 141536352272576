// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
  transition: 0.2s;
}
.register-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.register-card .heading {
  display: flex;
  justify-content: space-between;
}
.register-card .heading .blocked-alert {
  background-color: rgb(206, 103, 103);
  padding: 5px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MyProfile/MyProfile.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;AACI;EACI,2CAAA;AACR;AAEI;EACI,aAAA;EACA,8BAAA;AAAR;AAEQ;EACI,oCAAA;EACA,YAAA;EACA,kBAAA;AAAZ","sourcesContent":[".register-card {\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n    border-radius: 5px;\n    background-color: white;\n    transition: 0.2s;\n\n    &:hover {\n        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n    }\n\n    .heading {\n        display: flex;\n        justify-content: space-between;\n\n        .blocked-alert {\n            background-color: rgb(206, 103, 103);\n            padding: 5px;\n            border-radius: 5px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
