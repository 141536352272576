import { API } from "../axios";

export async function getReportFields() {
    try {
        const res = await API.get('Report/GetReportFields');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export async function getReports(fields: string[], org: number) {
    try {
        return API.post('Report/GetReport/' + org, fields,
            {
                headers:
                {
                    'Content-Disposition': "attachment; filename=Report.zip",
                    'Content-Type': 'application/json'
                },
                responseType: 'arraybuffer',
            }
        )
    } catch (error) {
        console.log('Error:', error);
    }
}