import Faq from "../../models/Faq";
import { API } from "../axios";

async function getAllFaq() {
    try {
        const res = await API.get('Faq/GetFaqs');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function saveFaq(faq: Faq) {
    try {
        const res = await API.post('Faq/SaveFaq', faq);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function deleteFaq(faqId: number) {

    try {
        const res = await API.get('Faq/DeleteFaq/?faqId=' + faqId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export { getAllFaq, saveFaq, deleteFaq }