import {
  McButton,
  McInput,
  McModal,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import { useState } from "react";

const BlockButton = (prop: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [showBlockInput, setShowBlockInput] = useState(false);

  const blockReasons = [
    "Card lost",
    "End of employment",
    "Retired",
    "Multiple active cards",
    "Other",
  ];

  const block = async (event: any) => {
    if (event.detail.action === "block") {
      setReason("");
      setLoading(true);
      await prop.cardAction({ reason, isBlock: !prop.unblock });
      setLoading(false);
    }
    setOpenModal(false);
  };

  const handleBlockReason = (event: any) => {
    if (event.target.value[0] === "Other") {
      setReason("");
      setShowBlockInput(true);
    } else {
      setReason(event.target.value[0]);
      setShowBlockInput(false);
    }
  };

  return (
    <>
      <McModal
        heading={`${prop.unblock ? "Unblock" : "Block"} card`}
        dimension="small"
        fit="small"
        height="auto"
        open={openModal}
        focusstartanchorattribute="true"
        closing={block}
      >
        <div style={{ margin: "10px 0" }}>
          {!prop.unblock && (
            <McSelect
              name="blockReason"
              label="Reason"
              options={blockReasons}
              change={handleBlockReason}
            ></McSelect>
          )}

          {(prop.unblock || showBlockInput) && (
            <div style={{marginTop: 15}}>
              <McInput
                fit="small"
                name="name"
                label={`Please enter the reason for ${
                  prop.unblock ? "Unblock" : "Block"
                }ing card`}
                type="text"
                value={reason}
                input={(e: any) => setReason(e.target.value)}
              ></McInput>
            </div>
          )}
        </div>

        <McButton
          slot="primaryAction"
          variant="primary"
          loading={loading}
          disabled={!reason}
          dialogAction="block"
        >
          {prop.unblock ? "Unblock" : "Block"}
        </McButton>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
        >
          Cancel
        </McButton>
      </McModal>
      <McButton
        label={prop.unblock ? "Unblock" : "Block"}
        disabled={prop.disabled}
        click={() => setOpenModal(true)}
      ></McButton>
    </>
  );
};

export default BlockButton;
