// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-wrapper {
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
}

.sidenav-container {
  position: fixed;
  z-index: 18;
}

.header {
  position: absolute;
  z-index: 15;
  height: 90px;
}

.main {
  margin-left: 110px;
  margin-top: 100px;
}

.footer {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-left: 20px;
  height: 20px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  top: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-size-adjust: auto;
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/pageLayout/PageLayout.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACD,2CAAA;EACC,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;AAAF","sourcesContent":[".pagination-wrapper{\n  margin-top: 0.5rem;\n  margin-bottom: 1.1rem;\n}\n\n.sidenav-container {\n  position: fixed;\n  z-index: 18;\n}\n\n\n.header {\n  position: absolute;\n  z-index: 15; \n  height: 90px;\n}\n\n.main {\n  margin-left: 110px;\n  margin-top: 100px; \n}\n\n.footer {\n  background-color: #ffffff;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  margin-left: 20px;\n  height: 20px;\n  width:100%;\n  overflow: hidden;\n  text-align: center;\n  top: auto;\n box-shadow: 0px 0px 10px rgba(0,0,0,0.2);\n  text-size-adjust: auto;\n  font-size: 14px;\n  padding-top: 3px;\n  padding-bottom: 25px;\n \n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
