import { useEffect, useState } from 'react'
import { McButton, McLoadingIndicator, McNotification } from '@maersk-global/mds-react-wrapper';
import './MyProfile.scss';
import { getProfile, saveProfile } from '../../api/services/user';
import User from '../../models/User';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import { useAccount } from '@azure/msal-react';

const MyProfile = () => {
  const [userDetail, setUserDetail] = useState<User>({
    userId: "",
    fullName: "",
    email: "",
    primaryCard: "",
    secondaryCard: "",
    noOfCards: 1,
    personalEmail: "",
    organisation: -1,
    bankRegistration: "",
    bankAccount: "",
    userStatus: 0,
    rbankAccount: "",
    organisationName: "",
    isCardLost: false,
    isCardBlocked: false,
  });

  const [popup, setPopup] = useState({
    heading: '',
    body: '',
    appearance: '',
    hidden: true
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const regnoRegex = /^\d{4}$/; // for bank registration number
  const bankAccRgx = /^\d{10}$/; // for bank account
  const pemailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // for personal email
  const account = useAccount();

  useEffect(() => {
    fetchProfile();
  }, [])

  const fetchProfile = async () => {
    setLoading(true);
    const profile = await getProfile(account?.idTokenClaims?.uid as string);
    if (profile) {
      localStorage.setItem('userRegistered', "true");
      setUserDetail(profile);
    }
    setLoading(false);
  }
  const handleSubmit = async (event: any) => {
    const errors: string[] = [];
    if (!regnoRegex.test(userDetail.bankRegistration)) {
      errors.push("Bank registration number is invalid. Please enter a 4-digit number.");
    }

    if (userDetail.primaryCard.replaceAll('.', '').length != 8) {
      errors.push("Rebate card number is invalid. Please enter a 8-digit number.");
    }
    if (userDetail.noOfCards <= 0 || userDetail.noOfCards > 5) {
      errors.push("Please enter valid card count");
    }
    if (!bankAccRgx.test(userDetail.bankAccount)) {
      errors.push("Please enter a 10-digit number. Prefix with 0's if less.");
    }
    if (localStorage.getItem("userRegistered") != "true" && userDetail.bankAccount != userDetail.rbankAccount) {
      errors.push("Bank account number doesn't match");
    }
    if (userDetail.organisation <= -1) {
      errors.push("Please select a Organization");
    }
    
    if (userDetail.personalEmail.length > 0 && !pemailRegex.test(userDetail.personalEmail)) {
      errors.push("Personal email is invalid. Please enter a valid email address.");
    }
    // if there are errors, display them all
    if (errors.length > 0) {
      console.log('Form Errors:', errors);
      setPopup({
        heading: 'Error',
        body: 'Please fill all the fields',
        appearance: 'error',
        hidden: false
      });
      return;
    }
    setSaveLoading(true);
    await saveProfile(userDetail);
    setSaveLoading(false);
    await fetchProfile();
    setPopup({
      heading: 'Success',
      body: localStorage.getItem("userRegistered") === "true" ? 'Your details have been updated successfully!' : 'You have been registered successfully!',
      appearance: 'success',
      hidden: false
    });
    setTimeout(() => {
      setPopup((prev) => ({ ...prev, hidden: true }));
    }, 4000);
  };

  return (
    <div className='container-fluid'>
      {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }} > <McLoadingIndicator /></div >
        : <div className='register-card'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5px', padding: '20px' }}>
            <McNotification
              heading={popup.heading}
              body={popup.body}
              appearance={popup.appearance as any}
              hidden={popup.hidden}
              icon={''}
              close={() => {
                setPopup({ ...popup, hidden: true })
              }}
            >
            </McNotification>
            <div className='heading'>
              <h3>Card Registration</h3>
              {userDetail.isCardBlocked && <h6 className='blocked-alert'>Your Card is Blocked</h6>}
            </div>
            <RegisterForm user={userDetail} setUser={setUserDetail} update={localStorage.getItem("userRegistered") === "true"} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '1.5px', padding: '15px' }}>
            <McButton slot="primaryAction" variant="primary" dialogAction="update" label={localStorage.getItem("userRegistered") === "true" ? "Update" : "Save"} fit="medium" loading={saveLoading} click={(e: CustomEvent) => handleSubmit(e)}></McButton>
          </div>
        </div >}
    </div >
  )
}

export default MyProfile
