import React from 'react';
import { McIcon } from '@maersk-global/mds-react-wrapper';

const SideBarData = [
    {   id: 0,
        title: 'My Profile',
        path: '/my-profile',
        icon: <McIcon icon="captain-hat" color="white"></McIcon>,
        hoverIcon: <McIcon icon="captain-hat" color="#42B0D5"></McIcon>,
        cName: 'nav-text'
    },
    {   id: 1,
        title: 'Lost my Card',
        path: '/lost-my-card',
        icon: <McIcon icon="magnifying-glass" color="white"></McIcon>,
        hoverIcon: <McIcon icon="magnifying-glass" color="#42B0D5"></McIcon>,
        cName: 'nav-text'
    },
    {   id: 2,
        title: 'News',
        path: '/news',
        icon: <McIcon icon="oven" color="white"></McIcon>,
        hoverIcon: <McIcon icon="oven" color="#42B0D5"></McIcon>,
        cName: 'nav-text'
    },
    {   id: 3,
        title: 'FAQ',
        path: '/faq',
        icon: <McIcon icon="light-bulb" color="white"></McIcon>,
        hoverIcon: <McIcon icon="light-bulb" color="#42B0D5"></McIcon>,
        //icon: <IoIcons.IoMdHelpCircle />,
        cName: 'nav-text'
    },
    {   id: 4,
        title: 'User Management',
        path: '/user-management',
        icon: <McIcon icon="person" color="white"></McIcon>,
        hoverIcon: <McIcon icon="person" color="#42B0D5"></McIcon>,
        cName: 'nav-text',
        role: ['Admin', 'SuperAdmin']
    },
    {   id: 5,
        title: 'Organizations',
        path: '/organizations',
        icon: <McIcon icon="org-chart" color="white"></McIcon>,
        hoverIcon: <McIcon icon="org-chart" color="#42B0D5"></McIcon>,
        cName: 'nav-text',
        role: ['SuperAdmin']
    },
    {   id: 6,
        title: 'Reports',
        path: '/reports',
        icon: <McIcon icon="clipboard-check-text" color="white"></McIcon>,
        hoverIcon: <McIcon icon="clipboard-check-text" color="#42B0D5"></McIcon>,
        role: ['Admin', 'SuperAdmin'],
        cName: 'nav-text'
    }

];
export const getNavData = () =>SideBarData;
