import React from 'react';
import { Navigate } from 'react-router-dom';
import useCurrentRole from '../../hooks/useCurrentRole';

const Home = () => {
  const currentRole = useCurrentRole();
  const focusNav = currentRole.includes('User') ? 'news' : 'user-management';
  return (
    currentRole.includes('User')
      ? <Navigate to="/news" />
      : <Navigate to="/user-management"/>
  )
}

export default Home;
