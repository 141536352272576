import { useAccount } from '@azure/msal-react';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useCurrentRole from '../../hooks/useCurrentRole';
import { getNavData } from '../SideNavData/SideNavData';
import './SideNav.scss';

export const SideNav: React.FC<any> = ({ focusNav }) => {
    const currentRole = useCurrentRole();
    const navData = getNavData();
    const allTabsName = navData.map((ele) => ele.path.split('/').splice(1)[0]);  

    const location = useLocation();

    function getCurrentPageName() {
        const currentPage = location.pathname.split('/').splice(1)[0];
        if (currentRole.includes('User')) {
          return allTabsName.includes(currentPage) ? currentPage : 'news';
        } else if (currentRole.includes('Admin') || currentRole.includes('SuperAdmin')) {
          return allTabsName.includes(currentPage) ? currentPage : 'user-management';
        }
        return 'my-profile';
      }

    const [currentNav, setCurrentNav] = useState(getCurrentPageName());
    

    const isNavAllowed = (item: any) => {
        return item.role
            ? currentRole.some(role => item.role.indexOf(role) >= 0)
            : true;
    }

    return (
        <div className={`sidenavClosed ${!isNavAllowed ? 'remove-side-nav' : ''}`}>
            {navData.map((item, idx) => {
                if(isNavAllowed(item)) {
                    return ( 
                        <NavLink 
                            key={item.id} 
                            className={`sideitem close-panel-hover ${currentNav === item.path.split('/').splice(1)[0] ? 'current-nav-item' : ''}`} 
                            to={(item.path === '#' || item.path === '/') ? '/my-profile' : item.path} 
                            onClick={() => setCurrentNav(item.path.split('/').splice(1)[0])}
                        >
                            <span className={`icon-prime ${currentNav === item.path.split('/').splice(1)[0] ? 'current-nav-icon-hide' : ''}`}>{item.icon}</span>
                            <span className={`icon-hover ${currentNav === item.path.split('/').splice(1)[0] ? 'current-nav-icon-show' : ''}`}>{item.hoverIcon}</span>
                            <span className={`${currentNav === item.path.split('/').splice(1)[0] ? 'current-nav-linktext' : 'linkText'}`}>{item.title}</span>
                        </NavLink>
                    );
                }
            })}
        </div>
    )
}
