import {
  McButton,
  McInput,
  McLoadingIndicator,
  McModal,
  McNotification,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import React, { useContext, useEffect, useRef, useState } from "react";
import { html, TemplateResult } from "lit";
import { MdsTable } from "../../../components/Table/MdsTable";
import {
  TableCellTemplateDetails,
  TableColumn,
} from "@maersk-global/mds-components-community/mc-c-table/types";
import RegisterForm from "../../../components/RegisterForm/RegisterForm";
import User from "../../../models/User";
import { saveProfile, searchProfile } from "../../../api/services/user";
import { getOrganizationsByAdmin } from "../../../api/services/organization";
import { TableCellTemplates } from "@maersk-global/mds-components-community/mc-c-table/table-cell-templates";
import { blockCard, unBlockCard } from "../../../api/services/card";
import BlockButton from "../../../components/BlockButton/BlockButton";
import ActivityModal from "../../../components/ActivityModal/ActivityModal";
import CardContext from "../../../context/cardContext";
const Search = () => {
  const UserManagemntColumns: Array<TableColumn> = [
    {
      id: "fullName",
      label: "Name",
      width: "auto",
      disableSorting: true,
      nowrap: true,
      cellTemplate: (details): TemplateResult => {
        const handleEditClick = () => {
          setUserDetail(details.rowData as User);
          setOpenUserUpdateModal(true);
        };
        const showActivity = () => {
          setShowActivity({
            userID: details.rowData.userId,
            name: details.rowData.fullName,
          });
        };
        return html` <div
          style="display: flex; justify-content: space-between; gap: 10px"
        >
          <div>${details.value}</div>
          <div>
            <mc-button
              hiddenlabel
              icon="pencil"
              variant="primary"
              fit="small"
              @click="${handleEditClick}"
            ></mc-button>
            <mc-button
              hiddenlabel
              icon="arrow-anti-clockwise-clock"
              variant="primary"
              fit="small"
              @click="${showActivity}"
            ></mc-button>
          </div>
        </div>`;
      },
    },
    {
      id: "userId",
      label: "Emp Id",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "email",
      label: "Email",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "primaryCard",
      label: "Rebate Card",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "noOfCards",
      label: "No. of Rebate Cards",
      width: "20px",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "personalEmail",
      label: "Personal Email",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "organisationName",
      label: "Organization",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "bankRegistration",
      label: "Bank Regd.No",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "bankAccount",
      label: "Bank AC.No",
      width: "auto",
      nowrap: true,
      disableSorting: true,
    },
    {
      id: "isCardBlocked",
      label: "Status",
      width: "auto",
      nowrap: true,
      disableSorting: true,
      cellTemplate: (details): TemplateResult => {
        const status: TableCellTemplateDetails = details;
        const color = details.value ? "error" : "success";
        status.value = details.value
          ? details.rowData.isCardLost
            ? "Lost & Blocked"
            : "Blocked"
          : "Active";
        return TableCellTemplates.tag(status, color);
      },
    },
  ];
  const { fetchData } = useContext(CardContext);
  const [searchParams, setSearchParams] = React.useState("");
  const [userSelected, setUserSelected] = useState([]);
  const [isBlockDisabled, setisBlockDisabled] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [checkSearch, setCheckSearch] = React.useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<User[]>([]);
  const [selectOrganization, setSelectOrganization] =
    React.useState<any>("All");
  const [openUserUpdateModal, setOpenUserUpdateModal] = useState(false);
  const [org, setOrg] = useState<any>([]);
  const [showActivity, setShowActivity] = useState<any>(null);
  const [userDetail, setUserDetail] = useState<User>({
    userId: "",
    fullName: "",
    email: "",
    primaryCard: "",
    noOfCards: 1,
    personalEmail: "",
    organisation: 0,
    bankRegistration: "",
    bankAccount: "",
    userStatus: 0,
    rbankAccount: "",
    organisationName: "",
    isCardLost: false,
    isCardBlocked: false,
  });
  const [popup, setPopup] = useState({
    heading: "",
    body: "",
    appearance: "",
    hidden: true,
  });
  const selectedValues = useRef<number[]>([]);
  const regnoRegex = /^\d{4}$/; // for bank registration number
  const bankAccRgx = /^\d{10}$/; // for bank account
  const pemailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // for personal email

  useEffect(() => {
    fetchOrg();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    setCheckSearch(true);
    const orgId =
      selectOrganization === "All" ? -1 : selectOrganization[0].value;
    const profile = await searchProfile(
      orgId,
      searchParams ? searchParams : "*"
    );
    if (profile) {
      setTableData(profile);
    }
    setLoading(false);
  };

  const fetchOrg = async () => {
    const orgs = await getOrganizationsByAdmin();
    if (orgs.length === 1) {
      setSelectOrganization(orgs);
    }
    setOrg(orgs);
  };

  const columnSortHandler = (event: CustomEvent): void => {
    event.preventDefault();
  };

  const rowSelection = (event: CustomEvent): void => {
    setUserSelected(event.detail.selectedValues);
    selectedValues.current = event.detail.selectedValues;
    if (selectedValues.current.length <= 0) {
      setisBlockDisabled(true);
      return;
    }
    setisBlockDisabled(false);
  };

  const inputHandler = (event: any) => {
    setSearchParams(event.target.value);
  };

  const inputSelectOrganization = (event: any) => {
    setSelectOrganization(event.target.value);
  };

  const updateUser = async (event: CustomEvent) => {
    if (event.detail.action === "update") {
      const errors: string[] = [];
      if (!regnoRegex.test(userDetail.bankRegistration)) {
        errors.push(
          "Bank registration number is invalid. Please enter a 4-digit number."
        );
      }
      if (userDetail.primaryCard.replaceAll(".", "").length != 8) {
        errors.push(
          "Rebate card number is invalid. Please enter a 8-digit number."
        );
      }
      if (userDetail.noOfCards <= 0 || userDetail.noOfCards > 5) {
        errors.push("Please enter valid card count");
      }
      if (!bankAccRgx.test(userDetail.bankAccount)) {
        errors.push("Please enter a 10-digit number. Prefix with 0's if less.");
      }
      if (!userDetail.organisation || userDetail.organisation <= -1) {
        errors.push("Please select a Organization");
      }
      if (
        userDetail.personalEmail.length > 0 &&
        !pemailRegex.test(userDetail.personalEmail)
      ) {
        errors.push(
          "Personal email is invalid. Please enter a valid email address."
        );
      }
      // if there are errors, display them all
      if (errors.length > 0) {
        console.log("Form Errors:", errors);
        setPopup({
          heading: "Error",
          body: "Please fill all the required forms",
          appearance: "error",
          hidden: false,
        });
        return;
      }
      setIsUpdateLoading(true);
      await saveProfile(userDetail);
      setIsUpdateLoading(false);
      setLoading(true);
      await fetchUsers();
      setLoading(false);
    }
    setOpenUserUpdateModal(false);
    setPopup({
      heading: "Error",
      body: "Please fill all the required forms",
      appearance: "error",
      hidden: true,
    });
  };

  const updateBlockedStatus = async ({ reason, isBlock }: any) => {
    const userIds: string[] = [];
    const cardNos: string[] = [];
    userSelected.forEach((u) => {
      userIds.push(tableData[u].userId);
      cardNos.push(tableData[u].primaryCard);
    });

    selectedValues.current = [];
    setUserSelected([]);
    setisBlockDisabled(true);
    setShowTable(false);
    isBlock
      ? await blockCard(userIds, cardNos, reason)
      : await unBlockCard(userIds, cardNos, reason);
    setShowTable(true);
    fetchUsers();
    fetchData();
  };

  return (
    <>
      {!!showActivity && (
        <ActivityModal user={showActivity} setModal={setShowActivity} />
      )}
      {openUserUpdateModal && (
        <McModal
          heading="Update User"
          dimension="large"
          fit="large"
          open={openUserUpdateModal}
          closing={updateUser}
        >
          {!popup.hidden && (
            <McNotification
              heading={popup.heading}
              body={popup.body}
              appearance={popup.appearance as any}
              hidden={popup.hidden}
              icon={""}
              close={() => {
                setPopup({ ...popup, hidden: true });
              }}
            ></McNotification>
          )}
          <RegisterForm user={userDetail} setUser={setUserDetail} update />
          <McButton
            slot="primaryAction"
            variant="primary"
            loading={isUpdateLoading}
            dialogAction="update"
          >
            Update
          </McButton>
          <McButton
            slot="secondaryAction"
            variant="secondary"
            dialogAction="cancel"
          >
            Cancel
          </McButton>
        </McModal>
      )}

      <div
        style={{
          marginLeft: "10%",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "start",
          flexDirection: "row",
        }}
      >
        <div className="col-md-8">
          <McInput
            name="username"
            hiddenlabel
            // placeholder="Search Using Emp Id, Name or Card No. of Employee"
            input={inputHandler}
            onkeydown={(e) => {
              if (e.key === "Enter") {
                fetchUsers();
              }
            }}
          ></McInput>
        </div>
        <div className="col-md-2" style={{ marginLeft: "3px" }}>
          <McSelect
            hiddenlabel
            // placeholder=''
            options={[
              { label: org.length === 1 ? org[0].label : "All", value: -1 },
              ...(org.length === 1 ? [] : org),
            ]}
            selectedindex={[0]}
            change={inputSelectOrganization}
          ></McSelect>
        </div>
        <div className="col-md-1" style={{ marginLeft: "3px" }}>
          <McButton
            label="Search"
            click={fetchUsers}
            loading={loading}
          ></McButton>
        </div>
      </div>
      <div className="table-action">
        <BlockButton
          disabled={isBlockDisabled}
          cardAction={updateBlockedStatus}
        />
        <BlockButton
          unblock
          disabled={isBlockDisabled}
          cardAction={updateBlockedStatus}
        />
      </div>
      <div>
        <div style={{ marginBottom: "10px" }}>
          {checkSearch === false ? (
            <McNotification
              heading="Please search something to get the result"
              fit="small"
              appearance="info"
            ></McNotification>
          ) : showTable ? (
            <MdsTable
              data={tableData}
              columns={UserManagemntColumns}
              dataLoading={loading}
              onRowSelection={rowSelection}
              onSorting={columnSortHandler}
              rowselector
              maxHeight={"71vh"}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              {" "}
              <McLoadingIndicator />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
