import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, UNDO_COMMAND, SELECTION_CHANGE_COMMAND, FORMAT_TEXT_COMMAND, FORMAT_ELEMENT_COMMAND, $getSelection, $isRangeSelection, $createParagraphNode, CLEAR_EDITOR_COMMAND } from "lexical";
import { $wrapNodes } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, $isListNode, ListNode } from "@lexical/list";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import { McButton, McSelect } from "@maersk-global/mds-react-wrapper";
import undo from "../../assets/EditorIcons/arrow-counterclockwise.svg";
import redo from "../../assets/EditorIcons/arrow-clockwise.svg";
import bold from "../../assets/EditorIcons/type-bold.svg";
import italic from "../../assets/EditorIcons/type-italic.svg";
import underline from "../../assets/EditorIcons/type-underline.svg";
import strikethrough from "../../assets/EditorIcons/type-strikethrough.svg";
import leftAlign from "../../assets/EditorIcons/text-left.svg";
import centerAlign from "../../assets/EditorIcons/text-center.svg";
import rightAlign from "../../assets/EditorIcons/text-right.svg";
import justifyAlign from "../../assets/EditorIcons/justify.svg";


const LowPriority = 1;

const options = ["Normal", "Large Heading", "Small Heading", "Bulleted List", "Numbered List"]

function Divider() {
    return <div className="divider" />;
}

function BlockOptionsDropdownList({
    editor,
    blockType,
}: any) {
    const formatParagraph = () => {
        if (blockType !== "paragraph") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode());
                }
            });
        }
    };

    const formatLargeHeading = () => {
        if (blockType !== "h1") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode("h1"));
                }
            });
        }
    };

    const formatSmallHeading = () => {
        if (blockType !== "h2") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode("h2"));
                }
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
    };

    const handleFormatSelect = (event: any) => {
        const selectedValue = event.detail.selectedOptions[0];
        switch (selectedValue) {
            case "Normal":
                formatParagraph();
                break;
            case "Large Heading":
                formatLargeHeading();
                break;
            case "Small Heading":
                formatSmallHeading();
                break;
            case "Bulleted List":
                formatBulletList();
                break;
            case "Numbered List":
                formatNumberedList();
                break;
            default:
                break;
        }

    }

    return (
        <McSelect
            className="format-select"
            options={options}
            fit="medium"
            hiddenlabel
            selectedindex={[0]}
            width={100}
            change={handleFormatSelect}
        >
        </McSelect>
    );
}

export default function ToolbarPlugin(props: any) {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState("paragraph");
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey: any = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                }
            }
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    return (
        <div className="toolbar" ref={toolbarRef}>
            <button
                disabled={!canUndo}
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, null as any);
                }}
                className="toolbar-item spaced"
                aria-label="Undo"
            >
                <img className="icon" src={undo} alt="" />
            </button>
            <button
                disabled={!canRedo}
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, null as any);
                }}
                className="toolbar-item"
                aria-label="Redo"
            >
                <img className="icon" src={redo} alt="" />
            </button>
            <Divider />

            <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
            />
            <Divider />

            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
                className={"toolbar-item spaced " + (isBold ? "active" : "")}
                aria-label="Format Bold"
            >
                <img className="icon" src={bold} alt="" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                className={"toolbar-item spaced " + (isItalic ? "active" : "")}
                aria-label="Format Italics"
            >
                <img className="icon" src={italic} alt="" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
                aria-label="Format Underline"
            >
                <img className="icon" src={underline} alt="" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                className={
                    "toolbar-item spaced " + (isStrikethrough ? "active" : "")
                }
                aria-label="Format Strikethrough"
            >
                <img className="icon" src={strikethrough} alt="" />
            </button>
            <Divider />
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
                className="toolbar-item spaced"
                aria-label="Left Align"
            >
                <img className="icon" src={leftAlign} alt="" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
                className="toolbar-item spaced"
                aria-label="Center Align"
            >
                <img className="icon" src={centerAlign} alt="" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
                className="toolbar-item spaced"
                aria-label="Right Align"
            >
                <img className="icon" src={rightAlign} alt="" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
                className="toolbar-item"
                aria-label="Justify Align"
            >
                <img className="icon" src={justifyAlign} alt="" />
            </button>
            <McButton className="toolbar-clear" label="Clear" variant="filled" onClick={props.clear} />
        </div>
    );
}
