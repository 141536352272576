import { OrgModel } from "../../pages/Organizations/model";
import { API } from "../axios";

async function getOrganizations() {
    try {
        const res = await API.get('Organisation/GetOrganisations');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getOrganizationsByAdmin() {
    try {
        const orgOptions: any = [];
        const res = await API.get('Organisation/GetOrganisationsByAdmin');
        res.data.forEach((org: OrgModel) => {
            orgOptions.push({ value: org.orgId as number, label: org.name });
        });
        return orgOptions;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getOrganizationsAsDropdown() {
    try {
        const orgOptions: any = [];
        const res = await API.get('Organisation/GetOrganisations');
        res.data.forEach((org: OrgModel) => {
            orgOptions.push({ value: org.orgId as number, label: org.name });
        });
        return orgOptions;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function saveOrganizations(organization: OrgModel) {
    try {
        const res = await API.post('Organisation/SaveOrganisation', organization);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function deleteOrganization(organizationId: number) {

    try {
        const res = await API.get('Organisation/DeleteOrganisation?organisationId=' + organizationId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export { getOrganizations, saveOrganizations, deleteOrganization, getOrganizationsAsDropdown, getOrganizationsByAdmin }