import React from 'react'

function Footer() {
    return (
        <div className="footer">
        &#169; - {new Date().getFullYear()}  A.P Moller - Maersk - 1.0
    </div>
    )
}

export default Footer
