import { TableColumn } from '@maersk-global/mds-components-community/mc-c-table/types'
import { McModal } from '@maersk-global/mds-react-wrapper'
import { useEffect, useState } from 'react'
import { getUserActivity } from '../../api/services/user'
import { MdsTable } from '../Table/MdsTable'
import { html, TemplateResult } from 'lit';
import moment from 'moment'

const ActivityModal = ({ user, setModal }: any) => {
    useEffect(() => {
        getActivity();
    }, []);

    const activityColumn: Array<TableColumn> = [
        {
            id: 'action',
            label: 'Action',
            width: 'auto',
            nowrap: true,
        },
        {
            id: 'date',
            label: 'Date',
            width: 'auto',
            nowrap: true,
            cellTemplate: (details): TemplateResult => {
                return html`
              <div >${moment(details.value).format('lll')}</div>`;
            }
        },
        {
            id: 'comments',
            label: 'Comments',
            width: 'auto',
            nowrap: true,
        },
        {
            id: 'modifiedBy',
            label: 'Modified By',
            width: 'auto',
            nowrap: true,
        },

    ];
    const [activity, setActivity] = useState();

    async function getActivity() {
        const data = await getUserActivity(user.userID);
        setActivity(data);
    }

    return (
        <McModal
            heading={user.name + " (" + user.userID + ") - User Activity"}
            dimension="medium"
            fit="medium"
            open={true}
            focusstartanchorattribute="true"
            closing={() => { setModal(false) }}
        >
            {<MdsTable
                data={activity}
                columns={activityColumn}
                dataLoading={!activity}
                maxHeight={'300px'}
                defaultSort='date'
                sortDir="descending"
            />}
        </McModal>
    )
}

export default ActivityModal