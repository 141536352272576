import axios from 'axios';
import { loginRequest } from '../authConfig';
import { msalInstance } from '../index';

const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const cloudAPI = axios.create({
    baseURL: 'https://api.maersk.com/cloud-id-api/api',
    headers: {
        'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

cloudAPI.interceptors.request.use(async (config) => {
    const exp = Number(localStorage.getItem("tokenExp"));
    if (!localStorage.getItem('cloudApiToken') || exp < Date.now()) {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + 'UserManagement/GetCloudApiToken');
        localStorage.setItem('cloudApiToken', response.data.access_token)
        localStorage.setItem('tokenExp', response.data.expires_on + '000')
    }
    config.headers.Authorization = `Bearer ${localStorage.getItem('cloudApiToken')}`;
    config.headers['Consumer-Key'] = process.env.REACT_APP_CONSUMER_KEY;
    return config;
});

API.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()[0];
        const msalResponse = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account
        });
        response.headers.Authorization = `Bearer ${msalResponse.accessToken}`
        return response;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export { API, cloudAPI };