// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
  border: 2px solid black;
  margin-top: 20px;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}

tr:hover {
  background-color: #D6EEEE;
}

.table-action {
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/UserManagement/UserManagement.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,WAAA;EACA,uBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,YAAA;EACA,gBAAA;EACA,6BAAA;AAAF;;AAGA;EAAU,yBAAA;AACV;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAEF","sourcesContent":["\ntable {\n  border-collapse: collapse;\n  width: 100%;\n  border: 2px solid black;\n  margin-top: 20px;\n}\n\nth, td {\n  padding: 8px;\n  text-align: left;\n  border-bottom: 1px solid #DDD;\n}\n\ntr:hover {background-color: #D6EEEE;}\n\n.table-action {\n  display: flex;\n  justify-content: start;\n  gap: 10px;\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
