import { Navigate, Outlet } from 'react-router-dom';
import useCurrentRole from '../hooks/useCurrentRole';

const RequireAuth = ({ allowedRoles }) => {
    const currentRole = useCurrentRole();
    return (
        currentRole.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : <Navigate to="/" replace />
    )
}

export default RequireAuth