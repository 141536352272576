import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper';
import React, { useEffect, useState } from 'react'
import { getAllNews } from '../../api/services/news';
import { Editor } from '../../components/Lexical/Editor'

const News = () => {
  return (
    <div className="container-fluid">
      < Editor />
    </div>
  )
}

export default News