import { useAccount } from '@azure/msal-react'
import { useState, useEffect } from 'react';

const useCurrentRole = () => {
  const account = useAccount();
  const role = account?.idTokenClaims?.roles;
  return role ? role : ['User'];
}

export default useCurrentRole

export const useProfileData = () => {
  const account = useAccount();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [empid, setEmpId] = useState('');

  useEffect(() => {
    if (account) {
      setName(account.name || '');
      setEmail(account.username || '');
      setEmpId(account?.idTokenClaims?.uid as string || '');
    }
  }, [account]);

  return { name, email, empid };
};