import News from "../../models/News";
import { API } from "../axios";

async function getAllNews() {
    try {
        const res = await API.get('News/GetNews');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function saveNews(news: News) {
    try {
        const res = await API.post('News/SaveNews', news);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function deleteNews(newsId: number) {

    try {
        const res = await API.get('News/DeleteNews/?newsId=' + newsId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export { getAllNews, saveNews, deleteNews }