export class OrgResponseModel {
    TotalRecords!: number;
    TotalPages!: number;
    ItemsPerPage!: number;
    Page!: number;
    Data!: OrgDetailModel[];
}

export class OrgDetailModel {

    TotalRecords!: number;
    TotalPages!: number;
    ItemsPerPage!: number;
    Page!: number;

    
    id!: number;
    orgname!: string;
    

    Pages!: number;
    constructor() {
        this.ItemsPerPage = 25;
        this.Page = 1;
        this.TotalRecords= 0;
        this.TotalPages= 0;

        this.orgname = '';
       
        this.Pages=0;

       
    }
}

export class OrgModel {
    orgId?: number;
    name!: string;
}