import { TableColumn,TableSortDirection,TableTBodyTemplateDetails,} from '@maersk-global/mds-components-community/mc-c-table/types';
import { McCTable } from '@maersk-global/mds-react-wrapper';

export interface TableProps<T> {
  //data: Record<string, T>[];
  data: any;
  columns: TableColumn[];
  dataLoading: boolean;
  onSorting?: (e: CustomEvent<any>) => void;
  rowselector?: boolean;
  onRowSelection?: (e: CustomEvent<T>) => void;
  maxHeight?:string;
  defaultSort?:string;
  sortDir?:TableSortDirection;
}

export const MdsTable = <T extends unknown>({
  data,
  columns,
  dataLoading,
  onSorting,
  rowselector = false,
  onRowSelection,
  defaultSort,
  sortDir,
  maxHeight
}: TableProps<T>) => {
  let rowSelect = false;
  if (rowselector && data?.length !== 0) {
    rowSelect = true;
  }

  let loadTemplateBody = false;
  if (dataLoading || !data || data.length === 0) {
    loadTemplateBody = true;
  }

  const tbodyTemplateNoData = (details: TableTBodyTemplateDetails) => {
    if (dataLoading) {
      return details.html`
        <tbody>
          <tr>
            <td colspan=${columns.length + 1} style="padding: 64px;">
              <mc-loading-indicator label="Loading">
              </mc-loading-indicator>
            </td>
          </tr>
        </tbody>`;
    }
    return details.html`
     <tbody>
       <tr>
         <td colspan=${columns.length} style="padding: 64px;">
           <mc-notification
             heading="No data found"
             appearance="warning"
             icon="exclamation-triangle"
           >
           </mc-notification>
         </td>
       </tr>
     </tbody>`;
  };

  return (
    <section className='layout layout--center paginated-data-wrapper'>
      <div className='layout--s layout--margin-32'>
        <McCTable
          data={data}
          columns={columns}
          stickyheader
          height='auto'
          customstyles={`.mds-table--scrollable{max-height:${maxHeight}}`}
          verticallinestyle='solid'
          fit='small'
          defaultsortcolumnid={defaultSort}
          defaultsortdirection={sortDir}
          recordsperpage={10}
          sorted={onSorting}
          rowselector={rowSelect}
          rowselectchange={onRowSelection}
          tbodyTemplate={
            !loadTemplateBody
              ? undefined
              : (details: TableTBodyTemplateDetails) => tbodyTemplateNoData(details)
          }
        ></McCTable>
      </div>
    </section>
  );
};
