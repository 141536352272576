import Theme from "./Theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import React, { useEffect, useRef, useState } from 'react';
import ToolbarPlugin from "./Toolbar";
import { ReadOnlyEditor } from "./ReadonlyEditor";
import { McButton, McLoadingIndicator, McModal } from "@maersk-global/mds-react-wrapper";
import { $getRoot, CLEAR_EDITOR_COMMAND, EditorState, LexicalEditor } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import "./styles.scss"
import useCurrentRole from "../../hooks/useCurrentRole";
import News from "../../models/News";
import { deleteNews, getAllNews, saveNews } from "../../api/services/news";


function RefPlugin({ editorRef }: { editorRef: any }) {
  const [editor] = useLexicalComposerContext()
  editorRef.current = editor
  return null
}

const editorConfig = {
  namespace: "NewsEditor",
  theme: Theme,
  onError: (error: any) => { throw error },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
  ]
};

export function Editor() {
  const [postLoading, setPostLoading] = useState(false)
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState<News[]>([]);
  const [isPostEnabled, setIsPostEnabled] = useState(true);
  const [updateNewsIdx, setupdateNewsIdx] = useState<number>(-1)
  const [openUpdateModel, setOpenUpdateModel] = useState(false)
  const editorRef = useRef<LexicalEditor | null>(null)
  const [title, setTitle] = useState('');
  const role = useCurrentRole();
  const scrollRef = useRef<any>();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setLoading(true);
    const news = await getAllNews();
    if (news) {
      setNews(news);
    }
    setLoading(false);
  }
  function onNewsChange(editorState: EditorState, editor: LexicalEditor) {
    editor.update(() => {
      const text = $getRoot().getTextContent();
      (text && title) ? setIsPostEnabled(false) : setIsPostEnabled(true);
    })
  }

  async function removeNews(index: number) {
    setNews(news => news.filter((n, idx) => idx != index));
    await deleteNews(news[index].newsId as number);
    fetchNews();
  }

  function updateNews(state: string, title: string, idx: number) {
    scrollRef.current.scrollIntoView();
    const editor = editorRef.current;
    editor?.setEditorState(editor.parseEditorState(state));
    setTitle(title);
    setupdateNewsIdx(idx);
  }

  function clearEditor() {
    const editor = editorRef.current;
    updateNewsIdx != -1 && setTitle('');
    editor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    setupdateNewsIdx(-1);
  }

  function updateNewsData(event: any) {
    if (event.detail.action === "update") {
      const editor = editorRef.current;
      editor?.update(async () => {
        const editorState = editor.getEditorState();
        const newsObj = {
          newsId: news[updateNewsIdx].newsId,
          title: title,
          content: JSON.stringify(editorState),
        }
        postNews(newsObj);
        setNews(prev => { prev[updateNewsIdx] = newsObj; return prev });
        setupdateNewsIdx(-1);
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      })
    }
    setOpenUpdateModel(false);
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;
    const editor = editorRef.current;
    editor?.update(() => {
      const news = $getRoot().getTextContent();
      (text && news) ? setIsPostEnabled(false) : setIsPostEnabled(true);
    })
    setTitle(text);
  }
  function submitNews() {
    const editor = editorRef.current;
    editor?.update(async () => {
      const editorState = editor.getEditorState();
      const newsObj = {
        title: title,
        content: JSON.stringify(editorState),
      }
      postNews(newsObj);
      setNews(prev => [newsObj, ...prev]);
      setTitle('');
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    })
  }

  async function postNews(newsObj: News) {
    setPostLoading(true);
    await saveNews(newsObj);
    setTitle('');
    setPostLoading(false);
    fetchNews();
  }

  return (

    loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }} > <McLoadingIndicator /></div >
      : <>
        <McModal
          heading="Update News?"
          dimension="small"
          fit="small"
          open={openUpdateModel}
          focusstartanchorattribute="true"
          closed={updateNewsData}
        >
          <p>Are you sure you want to update this news?</p>
          <McButton slot="primaryAction" variant="primary" dialogAction="update">Update</McButton>
          <McButton slot="secondaryAction" variant="secondary" dialogAction="cancel">Cancel</McButton>
        </McModal>

        <div className="editor-card" hidden={role.includes("User")} ref={scrollRef}>
          <div className="news-heading" style={{ marginLeft: '15px' }}>
            <h2 style={{ paddingTop: '4px' }}>Add News</h2>
          </div>
          <hr style={{ margin: '0px', border: '1.5px solid' }} />
          <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container" slot="body">
              <input type="text" value={title} placeholder="Title" onChange={handleTitleChange} className="news-title" />
              <ToolbarPlugin clear={clearEditor} />
              <div className="editor-inner">
                <RichTextPlugin
                  contentEditable={<ContentEditable className="editor-input" />}
                  placeholder={<h1 className="editor-placeholder">News...</h1>}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <ClearEditorPlugin />
                <OnChangePlugin onChange={onNewsChange} />
                <HistoryPlugin />
                <RefPlugin editorRef={editorRef} />
                <ListPlugin />
              </div>
              <div slot="actions">
                <McButton
                  loading={postLoading}
                  style={{ float: 'right' }}
                  appearance="default"
                  label={updateNewsIdx != -1 ? "Update" : "Post"}
                  disabled={isPostEnabled}
                  click={updateNewsIdx != -1 ? () => setOpenUpdateModel(true) : submitNews}
                >
                </McButton>
              </div>
            </div>
          </LexicalComposer>
        </div>

        <div className="news-container">
          <h1>News</h1>
          {news.map((n, i) =>
            <ReadOnlyEditor newsData={n} key={n.newsId} deleteN={removeNews} updateNews={updateNews} idx={i} />
          )}
        </div>
      </>
  );
}
