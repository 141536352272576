import React, { useState } from 'react';
import { McButton, McIcon } from '@maersk-global/mds-react-wrapper';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { Nav, Navbar, Dropdown, DropdownButton } from 'react-bootstrap';

import { loginRequest } from '../../authConfig';
import './TopBar.scss';


export const TopBar: React.FC<any> = (props) => {

  const [showProfilePicker, setShowProfilePicker] = useState(false);
  const { instance } = useMsal();
  const activeAccount = useAccount();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest)
      .catch((error) => console.log(error));
  };

  const handleLoginPopup = () => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
     */

    instance.loginPopup({
      ...loginRequest,
      redirectUri: '/redirect.html'
    }).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: '/', // redirects the top level app after logout
      account: instance.getActiveAccount(),
    });
  };

  const handleSwitchAccount = () => {
    setShowProfilePicker(!showProfilePicker);
  };

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   * */

  return (
    <AuthenticatedTemplate>
      <div className='topbar'>
        <div className='top-bar-container'>
          <a href='/news' className='logo-home'>
            <img src='maersk-logo.png' alt='maersk logo' height="50px" />
          </a>
          <div className='logo-text'>APMM REBATE PORTAL</div>
          <div className='utility-container'>
            <div className='signout-icon'>
              <McIcon icon='door-arrow-right' size="24"></McIcon>
            </div>

            <DropdownButton
              variant="light"
              drop="down"
              title={activeAccount ? activeAccount.name : 'Unknown'}
            >
              <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                Sign out
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    </AuthenticatedTemplate>
  );
};

