import { TableColumn } from '@maersk-global/mds-components-community/mc-c-table/types';
import { McCTable, McLoadingIndicator } from '@maersk-global/mds-react-wrapper'
import { useContext, useEffect, useRef, useState } from 'react'
import { blockCard } from '../../../api/services/card';
import BlockButton from '../../../components/BlockButton/BlockButton';
import { html, TemplateResult } from 'lit';
import ActivityModal from '../../../components/ActivityModal/ActivityModal';
import CardContext from '../../../context/cardContext';

const LostCard = () => {
  const manageAdminColumn: Array<TableColumn> = [
    {
      id: 'fullName',
      label: 'Name',
      width: 'auto',
      align: 'left',
      nowrap: true,
      disableSorting: true,
      cellTemplate: (details): TemplateResult => {
        const handleEditClick = () => {
          setShowActivity({ userID: details.rowData.userId, name: details.rowData.fullName })
        }
        return html`
    <div style='display: flex; justify-content: space-between; gap: 10px' >
      <div >${details.value}</div>
          <mc-button  hiddenlabel icon="arrow-anti-clockwise-clock" variant="primary" fit="small" @click="${handleEditClick}"></mc-button >
    </div>`;
      }
    },
    {
      id: 'userId',
      label: 'Emp Id',
      width: 'auto',
      nowrap: true,
      disableSorting: true

    },
    {
      id: 'email',
      label: 'Email',
      width: 'auto',
      nowrap: true,
      disableSorting: true

    },
    {
      id: 'primaryCard',
      label: 'Rebate Card',
      width: 'auto',
      nowrap: true,
      disableSorting: true

    },
    {
      id: 'organisationName',
      label: 'Organization',
      width: 'auto',
      nowrap: true,
      disableSorting: true

    }
  ];

  const [isBlockDisabled, setisBlockDisabled] = useState(true);
  const [cardsSelected, setCardsSelected] = useState([]);
  const [showActivity, setShowActivity] = useState<any>(null)
  const [showTable, setShowTable] = useState(true);
  const selectedValues = useRef<number[]>([])
  const { cardData, fetchData } = useContext(CardContext);

  useEffect(() => {
    fetchData();
  }, [])


  const tbodyTemplate = (details: any) => {
    return details.html`
    <tbody>
      <tr>
        <td colspan="6" style="padding: 64px;">
          <mc-loading-indicator label="Loading">
          </mc-loading-indicator>
        </td>
      </tr>
    </tbody>`
  }

  const noDataTemplate = (details: any) => {
    return details.html`
     <tbody>
       <tr>
         <td colspan=6 style="padding: 64px;">
           <mc-notification
             heading="No data found"
             appearance="warning"
             icon="exclamation-triangle"
           >
           </mc-notification>
         </td>
       </tr>
     </tbody>`;
  }



  function rowSelection(event: any) {
    setCardsSelected(event.detail.selectedValues);
    selectedValues.current = event.detail.selectedValues;
    if (selectedValues.current.length <= 0) {
      setisBlockDisabled(true);
      return;
    }
    setisBlockDisabled(false);
  }

  async function block({ reason }: any) {
    selectedValues.current = [];
    setCardsSelected([]);
    setisBlockDisabled(true);
    setShowTable(false);
    const userIds: string[] = [];
    const cardNos: string[] = [];
    cardsSelected.forEach(u => {
      userIds.push(cardData.lostCard[u].userId);
      cardNos.push(cardData.lostCard[u].primaryCard);
    });
    await blockCard(userIds, cardNos, reason);
    setShowTable(true);
    fetchData();
  }

  return (
    <>
      {!!showActivity && <ActivityModal user={showActivity} setModal={setShowActivity} />}
      <div className='table-action'>
        <BlockButton disabled={isBlockDisabled} cardAction={block} />
      </div>

      {showTable ?
        <McCTable
          data={cardData?.lostCard}
          columns={manageAdminColumn}
          rowselector
          selecteddatakeyvalues={cardsSelected}
          rowselectchange={rowSelection}
          tbodyTemplate={
            cardData?.loading
              ? tbodyTemplate
              : cardData?.lostCard.length === 0 ? noDataTemplate : undefined
          }
        /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }} > <McLoadingIndicator /></div >}
    </>
  )
}

export default LostCard