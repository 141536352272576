import { McTab, McTabBar } from "@maersk-global/mds-react-wrapper";
import { CardProvider } from "../../context/cardContext";
import BlockedCards from "./components/BlockedCards";
import LostCard from "./components/LostCard";
import ManageRoles from "./components/ManageRoles";
import Search from "./components/Search";
import "./UserManagement.scss";
import useCurrentRole from "../../hooks/useCurrentRole";
const UserManagement = () => {
  const role = useCurrentRole();

  return (
    <div className="container-fluid">
      <McTabBar currentindex={0} variant="stretched" fit="small">
        <CardProvider>
          <McTab slot="tab" label="Search" icon="magnifying-glass"></McTab>
          <div slot="panel">
            <Search />
          </div>
          <McTab slot="tab" label="Lost Cards" icon="credit-card-front"></McTab>
          <div slot="panel">
            <LostCard />
          </div>
          <McTab slot="tab" label="Blocked Cards" icon="times-circle"></McTab>
          <div slot="panel">
            <BlockedCards />
          </div>
          {role.includes("SuperAdmin") && (
            <>
              <McTab slot="tab" label="Manage Roles" icon="people"></McTab>
              <div slot="panel">
                <ManageRoles />
              </div>
            </>
          )}
        </CardProvider>
      </McTabBar>
    </div>
  );
};

export default UserManagement;
