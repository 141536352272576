import User from "../../models/User";
import { API } from "../axios";

async function saveProfile(profile: User) {
    try {
        const res = await API.post('UserProfile/SaveUserProfile/', profile);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function searchProfile(orgId: number, search: string) {
    try {
        const res = await API.get(`UserProfile/Search/${orgId}/${search}`);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getProfile(empId: string) {
    try {
        const res = await API.get('UserProfile/GetUserProfile/' + empId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getProfiles() {
    try {
        const res = await API.get('UserProfile/GetUserProfiles/');
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function getUserActivity(empId: string) {
    try {
        const res = await API.get('UserProfile/GetUserActivity/' + empId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function deleteProfile(empId: string) {
    try {
        const res = await API.get('UserProfsile/DeleteUserProfile/' + empId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

async function SaveUserLogin(empId: string) {
    try {
        const res = await API.get('UserProfile/SaveUserLogin?userId=' + empId);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
    }
}

export { saveProfile, getProfile, getProfiles, deleteProfile, searchProfile, SaveUserLogin, getUserActivity }