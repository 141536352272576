import { BrowserRouter as Router } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import Pages from "./pages/Pages";
import './App.css';
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: { instance: any }) => {

  return (
    <Router>
      <MsalProvider instance={instance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{ ...loginRequest }}
        >
          <Pages />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Router>
  );
}

export default App;
