import { createContext, useState } from "react";
import { getBlockedCards, getLostCards } from "../api/services/card";

const CardContext = createContext<any>(undefined!);

export function CardProvider({ children }: any) {
    const [cardData, setCardData] = useState<any>();

    async function fetchData() {
        setCardData({ loading: true });
        const lostCard = await getLostCards();
        const blockedCard = await getBlockedCards();
        setCardData({ lostCard, blockedCard, loading: false });
    }

    return (
        <CardContext.Provider value={{ cardData, fetchData }}>
            {children}
        </CardContext.Provider>
    )

}


export default CardContext;